import { FC } from "react";
import "./index.scss";
import { inputs } from "./constants";
import { ReactComponent as ContactsArrow } from "../../shared/assets/icons/contacts-arrow.svg";

export interface IService {
  id: number;
  title: string;
  img: string;
  classText: string;
  classBackground: string;
  status: boolean;
}

interface IModal {
  modal: boolean;
  toggleModal: () => void;
  pushSelectedService: IService | null | undefined;
}

const Form: FC<IModal> = ({ modal, toggleModal, pushSelectedService }) => (
  <div className="Form">
    <div className="__title">Закажите услугу</div>
    <div className="__input-forms">
      {inputs.map((item) => (
        <input className="__input-form" placeholder={item} key={item} />
      ))}
    </div>

    <div style={{ marginBottom: "2.4rem" }}>
      <div className="__subtitle">Услуга</div>
      <div className="__service" onClick={toggleModal}>
        {pushSelectedService ? (
          <div className="__service-title">{pushSelectedService.title} </div>
        ) : (
          <div className="__service-title">Выберите услугу</div>
        )}
        <div>
          <ContactsArrow className={modal ? "_accordion" : ""} />
        </div>
      </div>
    </div>

    <textarea className="__input-form" placeholder="Сообщение" />

    <div className="__button-block">
      <div className="__button">Заказать</div>
    </div>
  </div>
);

export default Form;
