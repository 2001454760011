export const vacancies = [
  {
    id: 1,
    title: "Frontend-разработчик",
    subtitle:
      "IT- компания Rowtech ищет целеустремлённого и амбициозного Frontend-разработчика в свою команду!",

    requirements: {
      title: "Требования:",
      items: [
        "- Опыт работы: 2-5 лет (Middle).",
        "- Знание: JavaScript/TypeScript, СSS, HTML.",
        "- Знание библиотеки React и идеологию Redux.",
        "- Умение работать со Swagger API.",
        "- ООП, классы, абстракции, шаблоны проектирования.",
        "- Понимание REST: взаимодействия с backend: отправка и получение JSON, отличия HTTP-методов.",
        "- Опыт работы с системами контроля версий.",
        "- Фреймворк Next.",
      ],
    },

    responsibilities: {
      title: "Обязанности:",
      items: [
        "- Разработка пользовательского интерфейса.",
        "- Работа с дизайн-макетами.",
        "- Адаптивная и мобильная разработка.",
        "- Взаимодействие с бэкендом.",
        "- Управление состоянием.",
        "- Оптимизация производительности.",
        "- Системы контроля версий.",
      ],
    },

    primaryRequirements: {
      title: "Основные требования:",
      items: [
        "- Знание общих принципов организации разработки ПО.",
        "- Желание учиться чему-то новому каждый день.",
        "- Быть внимательным к мелочам.",
        "- Умение грамотно и чётко формулировать свои мысли.",
      ],
    },

    offer: {
      title: "Что мы предлагаем:",
      items: [
        "- Удобный график работы.",
        "- Профессиональная команда и дружелюбный коллектив.",
        "- Возможность карьерного роста.",
        "- Работа в команде.",
      ],
    },

    sendCv: {
      title: "Отправьте CV",
      bold: "vacancy@row.tj",
      items: [
        "Заполните форму для рассмотрения вашей кандидатуры и приложите файл резюме в формате doc или pdf.",
        "Вы также можете отправить резюме по адресу ",
      ],
    },
  },
  {
    id: 2,
    title: "Менеджер по проектам",
    subtitle:
      "IT- компания Rowtech ищет целеустремлённого и амбициозного Менеджера проектов в свою команду!",

    requirements: {
      title: "Требования:",
      items: [
        "- Опыт работы: от 3 лет (Middle).",
        "- Опыт поиска внешних подрядчиков.",
        "- Опыт работы в команде разработки айти продуктов.",
        "- Опыт работы по Agile.",
      ],
    },

    responsibilities: {
      title: "Обязанности:",
      items: [
        "- Управление проектом разработки и продвижения айти продуктов.",
        "- Координация разработчиков и других технических специалистов.",
        "- Координация внутренних проектов.",
      ],
    },

    primaryRequirements: {
      title: "Основные требования:",
      items: [
        "- Знание общих принципов организации разработки ПО.",
        "- Желание учиться чему-то новому каждый день.",
        "- Быть внимательным к мелочам.",
        "- Умение грамотно и чётко формулировать свои мысли.",
      ],
    },

    offer: {
      title: "Что мы предлагаем:",
      items: [
        "- Удобный график работы.",
        "- Профессиональная команда и дружелюбный коллектив.",
        "- Возможность карьерного роста.",
        "- Работа в команде.",
      ],
    },

    sendCv: {
      title: "Отправьте CV",
      bold: "vacancy@row.tj",
      items: [
        "Заполните форму для рассмотрения вашей кандидатуры и приложите файл резюме в формате doc или pdf.",
        "Вы также можете отправить резюме по адресу ",
      ],
    },
  },
  {
    id: 3,
    title: "IT- консультант",
    subtitle:
      "IT- компания Rowtech ищет целеустремлённого и амбициозного IT- консультанта в свою команду!",

    requirements: {
      title: "Требования:",
      items: [
        "- Опыт работы: 2-5 лет (Middle).",
        "- Высшее образование.",
        "- Аналитическое мышление, грамотная устная и письменная речь, ответственность, самостоятельность, коммуникабельность, стрессоустойчивость, умение работать в команде.",
        "- Опытный пользователь ПК, владение офисными приложениями.",
        "- Способность анализа бизнес-процессов клиентов и выявление потенциальных улучшений.",
      ],
    },

    responsibilities: {
      title: "Обязанности:",
      items: [
        "- Настройка прав доступа пользователей.",
        "- Контроль справочников информационных систем.",
        "- Выявление проблем в работе действующего функционала, анализ необходимости выполнения доработок.",
        "- Тестирование доработок.",
        "- Отличные навыки общения, умение представлять сложные технические концепции простым языком.",
      ],
    },

    primaryRequirements: {
      title: "Основные требования:",
      items: [
        "- Знание общих принципов организации разработки ПО.",
        "- Желание учиться чему-то новому каждый день.",
        "- Быть внимательным к мелочам.",
        "- Умение грамотно и чётко формулировать свои мысли.",
      ],
    },

    offer: {
      title: "Что мы предлагаем:",
      items: [
        "- Удобный график работы.",
        "- Профессиональная команда и дружелюбный коллектив.",
        "- Возможность карьерного роста.",
        "- Работа в команде.",
      ],
    },

    sendCv: {
      title: "Отправьте CV",
      bold: "vacancy@row.tj",
      items: [
        "Заполните форму для рассмотрения вашей кандидатуры и приложите файл резюме в формате doc или pdf.",
        "Вы также можете отправить резюме по адресу ",
      ],
    },
  },
  {
    id: 4,
    title: "Тестировщик ПО / QA Engineer",
    subtitle:
      "IT- компания Rowtech ищет целеустремлённого и амбициозного Тестировщика ПО / QA Engineer в свою команду!",

    requirements: {
      title: "Требования:",
      items: [
        "- Опыт работы: 2-5 лет (Middle).",
        "- Понимание методологии и принципов тестирования ПО.",
        "- Знание общих принципов организации разработки ПО.",
        "- Опыт использования Windows.",
        "- Навыки тестирования API, SD.",
        "- Навыки тестирования мобильных приложений и web.",
        "- Практика создания и работы с тестовой документацией.",
        "- Умение работать с системами тест-менеджмента и баг-трекинга.",
      ],
    },

    responsibilities: {
      title: "Обязанности:",
      items: [
        "- Экспертный уровень в тестировании сайтов и веб-приложений.",
        "- Умение создавать план тестирования и вести тест кейсы.",
        "- Кроссплатформенное и кроссбраузерное тестирование.",
        "- Фиксирование результатов тестов в системе баг-трекинга.",
        "- Мониторинг и анализ полученных результатов.",
        "- Верификация выполненных требований.",
        "- Написание тестовой документации и поддержка её в актуальном состоянии.",
        "- Работа со спецификациями.",
      ],
    },

    primaryRequirements: {
      title: "Основные требования:",
      items: [
        "- Знание общих принципов организации разработки ПО.",
        "- Желание учиться чему-то новому каждый день.",
        "- Быть внимательным к мелочам.",
        "- Умение грамотно и чётко формулировать свои мысли.",
      ],
    },

    offer: {
      title: "Что мы предлагаем:",
      items: [
        "- Удобный график работы.",
        "- Профессиональная команда и дружелюбный коллектив.",
        "- Возможность карьерного роста.",
        "- Работа в команде.",
      ],
    },

    sendCv: {
      title: "Отправьте CV",
      bold: "vacancy@row.tj",
      items: [
        "Заполните форму для рассмотрения вашей кандидатуры и приложите файл резюме в формате doc или pdf.",
        "Вы также можете отправить резюме по адресу ",
      ],
    },
  },
];
