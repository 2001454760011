import { FC, useState, useEffect, useRef } from "react";
import { ReactComponent as ArrowDownIcon } from "../../shared/assets/icons/arrow-down.svg";
import "./index.scss";

export interface ISelectOption {
  label: string;
  value: number;
}

export interface ISelect {
  value: ISelectOption | null;
  options: ISelectOption[];
  onChange: (newValue: ISelectOption) => void;
}

const Select: FC<ISelect> = ({ value, options = [], onChange }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => setExpanded(!expanded);
  const handleChange = (item: ISelectOption) => {
    onChange(item);
    setExpanded(false);
  };
  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setExpanded(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div ref={selectRef} className={`Select ${expanded ? "_active" : ""}`}>
      <div className="__selected" onClick={toggleExpanded}>
        {value?.label} <ArrowDownIcon />
      </div>
      <div className="__options">
        {options.map((item) => (
          <div onClick={() => handleChange(item)} key={item.value}>
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Select;
