import { FC, useState } from "react";
import Select, { ISelectOption } from "..";
import { langOptions } from "../constants";

interface ILangSelect {
  onChange?: (value: ISelectOption) => void;
}

const LangSelect: FC<ILangSelect> = () => {
  const [lang, setLang] = useState<ISelectOption | null>(langOptions[0]);

  return (
    <Select
      value={lang}
      options={langOptions}
      onChange={(value) => setLang(value)}
    />
  );
};

export default LangSelect;
