import { FC } from "react";
import "./index.scss";
import { Link } from "react-router-dom";

const Logo: FC = () => (
  <Link to="/">
    <img className="Logo" src="/images/Logo.svg" alt="" />
  </Link>
);

export default Logo;
