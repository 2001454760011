import React, { FC, ReactNode, JSX, useState } from "react";
import { Link } from "react-router-dom";
import FloatButton from "../FloatButton";
import "./index.scss";

interface ILayoutContent {
  image?: string;
  children: ReactNode | JSX.Element;
  onNextPage?: () => void;
  title?: string;
  onNextPageScroll?: () => void;
}

const LayoutContent: FC<ILayoutContent> = ({
  image,
  children,
  onNextPage,
  title,
  onNextPageScroll,
}) => {
  const [next, setNext] = useState(false);

  const onBeforeNext = () => {
    setNext(true);
    setTimeout(() => onNextPage && onNextPage(), 500);
  };

  // const onBeforeNextScroll = () => {
  //   setNext(true);
  //   setTimeout(() => onNextPageScroll && onNextPageScroll(), 500);
  // };

  return (
    <div className={`LayoutContent ${next ? "_fade-out" : ""}`}>
      <div className="__main-title">{title} </div>
      <div className="__content">
        <img className="__image" src={image} alt="content" />
        {children}
        <div className="__float-btn">
          <FloatButton onClick={onBeforeNext} />
        </div>
        <div className="__order-button">
          <div className="__button">
            <Link className="__order-link" to="/contacts">
              Заказ Услуг
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutContent;
