import { ReactComponent as Ui } from "../../shared/assets/icons/ui.svg";
import { ReactComponent as Android } from "../../shared/assets/icons/android.svg";
import { ReactComponent as Web } from "../../shared/assets/icons/web.svg";
import { ReactComponent as Process } from "../../shared/assets/icons/process.svg";

export const blocks = [
  {
    id: 1,
    Icon: Android,
    title: "Мобильная разработка ",
    subtitle:
      "Собственная разработка мобильных приложений на платформах Android (Architecture Components).",
  },

  {
    id: 2,
    Icon: Ui,
    title: "UI/UX дизайн",
    subtitle:
      "Использование динамического прототипирования интерфейса и современных нотаций для моделирования бизнес-процессов.",
  },
  {
    id: 3,
    Icon: Web,
    title: "Веб разработка ",
    subtitle:
      "Использование гибких методологий разработки с предоставлением заказчику предсказуемого результата на каждой итеграции (AGILE).",
  },
  {
    id: 4,
    Icon: Process,
    title: "Автоматизация процессов",
    subtitle:
      "Электронный документооборот, цепочки утверждений, CRM, автоматизация поставок и управление производством.",
  },
];
