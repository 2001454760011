import { ReactComponent as Location } from "../../shared/assets/icons/location.svg";
import { ReactComponent as Call } from "../../shared/assets/icons/call.svg";
import { ReactComponent as Message } from "../../shared/assets/icons/message.svg";

export const aboutUs = [
  {
    id: 1,
    Icon: Location,
    text: "Душанбе, улица Садриддина Айни 48",
  },
  {
    id: 2,
    Icon: Call,
    text: "+992550650550",
  },
  {
    id: 3,
    Icon: Message,
    href: "mailto:Info@row.tj",
    text: "Info@row.tj",
  },
];
