import { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { MainLayout } from "../../layouts";
import { LayoutContent } from "../../components";
import { Routes } from "../../shared/utils/routes";
import { firstTwoPortfolio, portfolioBlocks } from "./constants";
import "./index.scss";
import MobileModal, { ISelectedPortfolio } from "./MobileModal";
import PortfolioBlock from "./PortfolioBlock";
import Modal from "./Modal";

const Protfolio: FC = () => {
  const navigate = useNavigate();
  const handleNextPage = () => {
    navigate(Routes.techStack);
  };

  const [mobileModal, setMobileModal] = useState(false);
  const [button, setButton] = useState(false);
  const buttonHandler = () => {
    setButton(!button);
  };

  const [selectedPortfolio, setSelectedPortfolio] = useState<
    ISelectedPortfolio | undefined
  >();

  const handleNextPageScroll = (next?: boolean) => {
    if (next) {
      navigate(Routes.about);
    } else {
      navigate(Routes.techStack);
    }
  };

  const windowWidth = window.innerWidth;

  // const [arrayToMap, setArrayToMap] = useState<IPortfolio[]>([]);

  // useEffect(() => {
  //   if (windowWidth < 575) {
  //     setArrayToMap(portfolioBlocks);
  //   } else {
  //     setArrayToMap(firstTwoPortfolio);
  //   }
  // }, []);

  const arrayToMap = windowWidth < 575 ? portfolioBlocks : firstTwoPortfolio;

  // const arrayToShow = useMemo(
  //   () =>
  //     portfolioBlocks.slice(
  //       0,
  //       window.innerWidth < 575 ? portfolioBlocks.length : 2
  //     ),
  //   []
  // );

  const mobileModalHandler = (id?: number) => {
    setMobileModal(!mobileModal);
    setSelectedPortfolio(portfolioBlocks.find((item) => item.id === id));
  };

  const [scroll, setScroll] = useState(0);

  const scrollRef = useRef<HTMLDivElement>(null);

  let activePlus: number;
  let activeDivision: number;
  let leftFirst: number;
  let leftSecond: number;

  if (windowWidth <= 575 && windowWidth > 360) {
    activePlus = 155;
    activeDivision = 320;
    leftFirst = 240;
    leftSecond = 80;
  } else if (windowWidth <= 360) {
    activePlus = 130;
    activeDivision = 260;
    leftFirst = 205;
    leftSecond = 55;
  }

  const getActive = (value: number) =>
    Math.ceil((value + activePlus) / activeDivision) - 1;
  const getLeft = (n: number) => n * leftFirst + n * leftSecond;

  const debouncedScroll = useDebouncedCallback((value: number) => {
    const n = getActive(value);
    const getCalcLeft = getLeft(n);

    setScroll(getCalcLeft);
    if (scrollRef.current) {
      scrollRef.current.scrollLeft = getCalcLeft;
    }
  }, 300);

  useEffect(() => {
    const scrollHandler = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const { scrollLeft } = target;
      setScroll(scrollLeft);
      debouncedScroll(scrollLeft);
    };

    scrollRef.current?.addEventListener("scroll", scrollHandler);

    return () =>
      scrollRef.current?.removeEventListener("scroll", scrollHandler);
  }, []);

  const active = getActive(scroll);
  return (
    <>
      <MainLayout
        onNextPageScroll={handleNextPageScroll}
        image="/images/photo3.png"
        mobileModal={mobileModal}
      >
        <LayoutContent
          image="/images/photo3.png"
          onNextPage={handleNextPage}
          title="Портфолио"
        >
          <div className="Portfolio">
            <div className="__blocks" ref={scrollRef}>
              {arrayToMap.map((block, index) => (
                <PortfolioBlock
                  key={block.id}
                  block={block}
                  mobileModalHandler={mobileModalHandler}
                  index={index}
                  active={active}
                />
              ))}
            </div>
            <div className="__btn-block">
              <div className="__btn" onClick={buttonHandler}>
                Смотреть
              </div>
            </div>
          </div>
        </LayoutContent>
      </MainLayout>
      {button && <Modal buttonHandler={buttonHandler} />}
      {mobileModal && (
        <MobileModal
          mobileModalHandler={mobileModalHandler}
          selectedPortfolio={selectedPortfolio}
          mobileModal={mobileModal}
        />
      )}
    </>
  );
};

export default Protfolio;
