export const socialMedias = [
  {
    id: 1,
    title: "instagram",
    href: "https://instagram.com/rowtech___?igshid=OGQ5ZDc2ODk2ZA==",
  },
  {
    id: 2,
    title: "email",
    href: "Info@row.tj",
  },
  {
    id: 3,
    title: "facebook",
    href: "https://www.facebook.com/profile.php?id=100088263955875",
  },
];
