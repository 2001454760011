import { FC, useEffect, useRef, useState } from "react";
import "./index.scss";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDebouncedCallback } from "use-debounce";
import SliderBlocks from "./SliderBlocks";

interface ITeam {
  id: number;
  image: string;
  name: string;
  job: string;
  status: boolean;
}

interface ISlider {
  items: ITeam[];
}

// console.log(Math.ceil((0 + 80) / 230) - 1);

const Slider: FC<ISlider> = ({ items = [] }) => {
  const listRef = useRef<HTMLDivElement>(null);
  // console.log(listRef);

  const [left, setLeft] = useState(0);

  console.log(Math.ceil((151 + 80) / 230));

  const getActive = (value: number) => Math.ceil((value + 80) / 230) - 1;

  const getCalcLeft = (n: number) => n * 150 + n * 80;

  const debounceScroll = useDebouncedCallback((value: number) => {
    const n = getActive(value);

    const leftInner = getCalcLeft(n);

    setLeft(leftInner);
    console.log(value);
    if (listRef.current) {
      listRef.current.scrollLeft = leftInner;
    }
  }, 300);

  useEffect(() => {
    const handler = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const { scrollLeft = 0 } = target;
      setLeft(scrollLeft);
      debounceScroll(scrollLeft);
    };
    listRef.current?.addEventListener("scroll", handler);

    return () => listRef.current?.removeEventListener("scroll", handler);
  }, []);

  const active = getActive(left);

  // console.log(active);

  return (
    <div className="Slider">
      <div className="__slider">
        <div className="__list" ref={listRef}>
          <div className="__inner-list">
            {items.map((item, idx) => (
              <SliderBlocks
                key={item.id}
                idx={idx}
                item={item}
                active={active}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="__dots">
        {items.map((item, idx) => (
          <div
            onClick={() => {
              const currLeft = getCalcLeft(idx);
              setLeft(currLeft);
              if (listRef.current) {
                listRef.current.scrollLeft = currLeft;
              }
            }}
            key={item.id}
            className={`__dot ${idx === active ? "_active" : ""}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Slider;
