/* eslint-disable no-unused-vars */

import { ReactNode } from "react";
import {
  About,
  Partners,
  OurStack,
  Portfolio,
  Contacts,
  Team,
  Home,
} from "../../routes";
import Vacancies from "../../routes/vacancies";

// eslint-disable-next-line no-shadow
export enum Routes {
  home = "/",
  about = "/about",
  partners = "/partners",
  techStack = "/tech-stack",
  portfolio = "/portfolio",
  contacts = "/contacts",
  team = "/team",
  vacancies = "/vacancies",
}

export interface IRouteListItem {
  path: Routes;
  element: ReactNode;
  title: string;
  visibility?: boolean;
  menuVisibility?: boolean;
}

export const routesList: IRouteListItem[] = [
  {
    path: Routes.home,
    element: <Home />,
    title: "Главная",
  },
  {
    path: Routes.about,
    element: <About />,
    title: "Чем занимаемся",
  },
  {
    path: Routes.portfolio,
    element: <Portfolio />,
    title: "Портфолио",
  },
  {
    path: Routes.techStack,
    element: <OurStack />,
    title: "Стэк технологии",
  },
  {
    path: Routes.partners,
    element: <Partners />,
    title: "Наши партнеры",
    visibility: false,
  },
  {
    path: Routes.team,
    element: <Team />,
    title: "Наша команда",
    visibility: false,
  },
  {
    path: Routes.contacts,
    element: <Contacts />,
    title: "Контакты",
    menuVisibility: false,
  },
  {
    path: Routes.vacancies,
    element: <Vacancies />,
    title: "Вакансии",
  },
];
