import { FC } from "react";
import "./index.scss";

interface IFloatButton {
  onClick?: () => void;
}

const FloatButton: FC<IFloatButton> = ({ onClick }) => (
  <div className="FloatButton" onClick={onClick}>
    <div className="__arrow-down" />
  </div>
);

export default FloatButton;
