import React, { FC, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import "./index.scss";
import Footer from "../Footer";
import { routesList } from "../../shared/utils/routes";
import { ReactComponent as CloseIcon } from "../../shared/assets/icons/close.svg";

interface IMenu {
  active: boolean;
  toggleMenu?: () => void;
}

const Menu: FC<IMenu> = ({ active, toggleMenu }) => {
  const [isHovered, setIsHovered] = useState(false);
  const visibleRoutes = useMemo(
    () =>
      routesList.filter(
        (item) => item.visibility !== false && item.menuVisibility !== false
      ),
    []
  );

  return (
    <div className={`Menu ${active ? "_active" : ""}`}>
      <div>
        <Footer />
      </div>

      <div className="__links-group">
        <div>
          {visibleRoutes.map((item) => (
            <div className="__links-block" key={item.path}>
              <NavLink
                className="__links"
                style={({ isActive }) => ({
                  color: isActive ? "rgb(134, 131, 131)" : "",
                })}
                to={item.path}
              >
                {item.title}
              </NavLink>
            </div>
          ))}
          <div
            className={`__close-icon ${isHovered ? "_hovered" : ""}`}
            onClick={toggleMenu}
            onTouchStart={() => setIsHovered(true)}
            onTouchEnd={() => setIsHovered(false)}
          >
            <CloseIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
