import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MainLayout } from "../../layouts";
import { LayoutContent } from "../../components";
import { Routes } from "../../shared/utils/routes";
import { vacancies } from "./constants";
import VacanciesBlocks from "./VacanciesBlocks";
import "./index.scss";
import VacanciesModal from "../../components/VacanciesModal";
import SelectedVacancyModal from "../../components/SelectedVacancyModal";
import { ReactComponent as CloseIcon } from "../../shared/assets/icons/close.svg";

const Vacancies = () => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);

  const [selectedVacancy, setSelectedVacancy] = useState<any>(null);
  const [isSelectedVacancy, setIsSelectedVacancy] = useState(false);

  const handleNextPage = () => {
    navigate(Routes.home);
  };

  const handleNextPageScroll = (next?: boolean) => {
    if (next) {
      navigate(Routes.techStack);
    } else {
      navigate(Routes.home);
    }
  };

  const handleModal = () => {
    setModal(!modal);
  };

  const chooseVacancy = (id?: number) => {
    setSelectedVacancy(vacancies.find((block) => block.id === id));
    setIsSelectedVacancy(!isSelectedVacancy);
  };

  if (modal) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }

  return (
    <>
      <MainLayout
        onNextPageScroll={handleNextPageScroll}
        image="/images/vacancies.png"
        mobileModal={modal}
      >
        <LayoutContent
          image="/images/vacancies.png"
          onNextPage={handleNextPage}
          title="Вакансии"
        >
          <div className="Vacancies">
            <div className="__block-container">
              {vacancies.map((item) => (
                <VacanciesBlocks
                  key={item.id}
                  item={item}
                  chooseVacancy={chooseVacancy}
                />
              ))}
            </div>
            <div className="__block-button">
              <div className="__button" onClick={handleModal}>
                Все вакансии
              </div>
            </div>
          </div>
        </LayoutContent>
      </MainLayout>
      <VacanciesModal
        modal={modal}
        handleModal={handleModal}
        vacancies={vacancies}
      >
        <div className="Vacancies-block">
          <div className="__main-title-block">
            <div className="__main-title">Актуальные вакансии</div>
            <div className="__mobile-close-icon" onClick={() => handleModal()}>
              <CloseIcon width={45} height={45} />
            </div>
          </div>
          {/* <div className="__search-block">
            <input
              placeholder="Введите поисковой запрос"
              className="__input"
              type="text"
            />
            <div className="__button-block">
              <img src="./images/search.png" alt="" />
            </div>
          </div>
          
          For future
          */}
          <div className="__block-container">
            {vacancies.map((item) => (
              <VacanciesBlocks
                chooseVacancy={chooseVacancy}
                key={item.id}
                item={item}
              />
            ))}
          </div>
        </div>
      </VacanciesModal>
      <SelectedVacancyModal
        selectedVacancy={selectedVacancy}
        isSelectedVacancy={isSelectedVacancy}
        chooseVacancy={chooseVacancy}
      />
    </>
  );
};

export default Vacancies;
