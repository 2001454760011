import React, { FC, ReactNode, JSX } from "react";
import "./index.scss";
import { ReactComponent as CloseIcon } from "../../shared/assets/icons/close.svg";

interface IVacanciesModal {
  modal: boolean;
  handleModal: () => void;
  vacancies: {
    id: number;
    title: string;
    subtitle: string;
  }[];
  children: ReactNode | JSX.Element;
}

const VacanciesModal: FC<IVacanciesModal> = ({
  modal,
  handleModal,
  vacancies,
  children,
}) => (
  <div
    onClick={(e) => {
      const target = e.target as HTMLElement;
      if (target.classList.contains("Vacancies-modal")) {
        handleModal();
      }
    }}
    className={`Vacancies-modal ${modal ? "_active" : ""} `}
  >
    <div className={`__icon ${modal ? "_visible" : ""}`}>
      <CloseIcon onClick={handleModal} width={45} height={45} />
    </div>
    <div className="__container">
      <div className={`__modal ${modal ? "_active" : ""}`}>{children}</div>
    </div>
  </div>
);

export default VacanciesModal;
