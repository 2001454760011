import { FC, useState, useEffect } from "react";
import "./index.scss";
import { socialMedias } from "./constants";
import Menu from "../Menu";
import LangSelect from "../Select/variant/_languages";
import MenuButton from "../MenuButton";
import Logo from "../Logo";
import NavbarBlock from "./NavbarBlocks";

interface INavbar {
  mobileModal?: boolean;
}

const Navbar: FC<INavbar> = ({ mobileModal }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleMenu = () => setExpanded(!expanded);

  const [isMenuVisible, setMenuVisible] = useState(true);
  const [startY, setStartY] = useState(0);

  useEffect(() => {
    const handleTouchStart = (e: TouchEvent) => {
      setStartY(e.touches[0].clientY);
    };

    const handleTouchMove = (e: TouchEvent) => {
      const currentY = e.touches[0].clientY;
      const deltaY = currentY - startY;

      if (mobileModal === false || mobileModal === undefined) {
        if (Math.abs(deltaY) > 60) {
          if (deltaY < 0) {
            setMenuVisible(false);
          } else {
            setMenuVisible(true);
          }
        }
      }
    };

    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("touchmove", handleTouchMove);

    return () => {
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchmove", handleTouchMove);
    };
  }, [startY]);

  return (
    <div className={`Navbar  ${expanded ? "_active" : ""} `}>
      <div
        className={`__container ${
          isMenuVisible ? "_transition" : "_isMenuVisible"
        } `}
      >
        <MenuButton toggleMenu={toggleMenu} />

        <div className="__logos">
          <Logo />
        </div>

        <div className="__social-media">
          {socialMedias.map((item) => (
            <NavbarBlock key={item.id} href={item.href} title={item.title} />
          ))}
        </div>
        <div className="__select">
          <LangSelect />
        </div>
      </div>
      <Menu active={expanded} toggleMenu={toggleMenu} />
    </div>
  );
};

export default Navbar;
