export const partners = [
  "/partners/mandiri-colored.png",
  "/partners/amartha-colored.png",
  "/partners/paxel-colored.png",
  "/partners/wahyo-colored.png",
  "/partners/cisco-colored.png",
  "/partners/legoas-colored.png",
  "/partners/lubna-colored.png",
  "/partners/riliv-colored.png",
  "/partners/universitas-colored.png",
];
