import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { MainLayout } from "../../layouts";
import { LayoutContent } from "../../components";
import { Routes } from "../../shared/utils/routes";
import "./index.scss";
import { partners } from "./constants";

const Partners: FC = () => {
  const navigate = useNavigate();
  const handleNextPage = () => {
    navigate(Routes.team);
  };
  const handleNextPageScroll = (next?: boolean) => {
    if (next) {
      navigate(Routes.techStack);
    } else {
      navigate(Routes.about);
    }
  };

  return (
    <MainLayout
      onNextPageScroll={handleNextPageScroll}
      image="/images/photo3.png"
    >
      <LayoutContent
        image="/images/photo3.png"
        onNextPage={handleNextPage}
        title="Наши партнеры"
      >
        <div className="Partners">
          <div className="__block">
            {partners.map((item) => (
              <img className="__img" src={item} alt="" key={item} />
            ))}
          </div>
          <div className="__btn">Посмотреть все </div>
        </div>
      </LayoutContent>
    </MainLayout>
  );
};
export default Partners;
