import { FC, ReactNode, JSX, useState } from "react";
import FloatButton from "../FloatButton";
import "./index.scss";

interface ITeamLayoutContent {
  children: ReactNode | JSX.Element;
  title: string;
  onNextPage: () => void;
}

const TeamLayoutContent: FC<ITeamLayoutContent> = ({
  children,
  title,
  onNextPage,
}) => {
  const [next, setNext] = useState(false);

  const onBeforeNext = () => {
    setNext(true);
    setTimeout(() => onNextPage && onNextPage(), 420);
  };

  return (
    <div className={`TeamLayoutContent ${next ? "_fade-out" : ""}`}>
      <div className="__main-title">{title}</div>
      <div className="__content">
        {children}
        <div className="__float-btn">
          <FloatButton onClick={onBeforeNext} />
        </div>
      </div>
    </div>
  );
};

export default TeamLayoutContent;
