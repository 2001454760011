import { FC, ReactNode, JSX, WheelEvent, useRef } from "react";
import "./index.scss";
import { useDebouncedCallback } from "use-debounce";
import { Footer, Logo, Navbar } from "../../components";
import { aboutUs } from "../../routes/contacts/constants";
import { socialMediasIcon } from "./constants";
import MobileFooterBlock from "./MobileFooterBlock";

interface IMainLayout {
  children: ReactNode | JSX.Element;
  image?: string;
  onNextPageScroll: (next?: boolean) => void;
  mobileModal?: boolean;
}

const MainLayout: FC<IMainLayout> = ({
  children,
  image,
  onNextPageScroll,
  mobileModal,
}) => {
  const mainRef = useRef<HTMLDivElement>(null);

  const nextPageDebounce = useDebouncedCallback((delta: number) => {
    const {
      scrollHeight = 0,
      scrollTop = 0,
      clientHeight = 0,
    } = mainRef.current || {};
    const scrollMaxStep = scrollHeight - clientHeight;

    if (scrollTop === 0 || scrollMaxStep - scrollTop < 2) {
      onNextPageScroll(scrollHeight === mainRef.current?.scrollTop);
    }
  }, 100);

  const handleScroll = (event: WheelEvent<HTMLDivElement>) => {
    nextPageDebounce(event.deltaY);
  };

  return (
    <div ref={mainRef} className="MainLayout" onWheel={handleScroll}>
      {/* <div ref={mainRef} className="MainLayout"> */}
      <div className="__aside">
        {image && <img className="__image" src={image} alt="content" />}
        <Footer />
      </div>
      <main>
        <div className="__logo">
          <Logo />
        </div>

        {children}
      </main>
      <Navbar mobileModal={mobileModal} />
      <footer className="__footer">
        <hr />
        <div className="__section">
          <div style={{ flex: 1, marginRight: "2rem" }}>
            <div className="__title-info">Контакты</div>
            {aboutUs.map(({ id, text, Icon, href }) => (
              <MobileFooterBlock
                id={id}
                text={text}
                Icon={Icon}
                href={href}
                key={id}
              />
            ))}
          </div>
          <div>
            <div className="__title-info">Соц сети</div>
            <div>
              {socialMediasIcon.map(({ Img, href, id }) => (
                <a href={href} key={id} className="__socila-medias">
                  <Img />
                </a>
              ))}
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default MainLayout;
