// export const technologies = [
//   {
//     id: 1,
//     title: "front-end",
//     images: ["./stack/js.png", "./stack/react.png", "./stack/msnet.png"],
//   },
//   {
//     id: 2,
//     title: "back-end",
//     images: [
//       "./stack/nodejs.png",
//       "./stack/docker.png",
//       "./stack/spring.png",
//       "./stack/rad.png",
//     ],
//   },
//   {
//     id: 3,
//     title: "mobile app development",
//     images: ["./stack/dart.png", "./stack/android.png"],
//   },
//   {
//     id: 4,
//     title: "servers",
//     images: ["./stack/amazon.png", "./stack/azure.png", "./stack/cloud.png"],
//   },
//   {
//     id: 5,
//     title: "ux/ui design",
//     images: [
//       "./stack/figma.png",
//       "./stack/sketch.png",
//       "./stack/ai.png",
//       "./stack/ps.png",
//       "./stack/xd.png",
//     ],
//   },
// ];

export const technologies = [
  "./stack/js.png",
  "./stack/cloud.png",
  "./stack/dart.png",
  "./stack/figma.png",
  "./stack/ai.png",
  "./stack/android.png",
  "./stack/docker.png",
  "./stack/spring.png",
  "./stack/ps.png",
  "./stack/react.png",
  "./stack/sketch.png",
  "./stack/msnet.png",
  "./stack/nodejs.png",
  "./stack/xd.png",
  "./stack/azure.png",
  "./stack/rad.png",
  "./stack/amazon.png",
  "./stack/vscode.png",
];
