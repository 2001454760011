import React, { FC } from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { MainLayout } from "../../layouts";
import { Routes } from "../../shared/utils/routes";
import TeamLayoutContent from "../../components/TeamLayoutContent";
import { team } from "./constants";
import { Slider } from "../../components";

const Team: FC = () => {
  const navigate = useNavigate();
  const handleNextPage = () => {
    navigate(Routes.home);
  };
  const handleNextPageScroll = (next?: boolean) => {
    if (next) {
      navigate(Routes.home);
    } else {
      navigate(Routes.portfolio);
    }
  };

  return (
    <MainLayout onNextPageScroll={handleNextPageScroll}>
      <TeamLayoutContent onNextPage={handleNextPage} title="Наша команда">
        <div className="slider-container">
          <Slider items={team} />
        </div>
      </TeamLayoutContent>
    </MainLayout>
  );
};
export default Team;
