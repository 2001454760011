import { FC, useEffect, useRef, useState } from "react";
import { portfolioBlocks } from "../constants";
import "./index.scss";
import { ReactComponent as CloseIcon } from "../../../shared/assets/icons/close.svg";
import ModalBlock from "./ModalBlock";

interface IModal {
  buttonHandler: () => void;
}

const Modal: FC<IModal> = ({ buttonHandler }) => {
  const [active, setActive] = useState(-1);
  const [detailVisibility, setDetailVisibility] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);

  const clearActive = () => {
    setDetailVisibility(false);
    setTimeout(() => setActive(-1), 300);
  };

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      const target = e.target as HTMLElement;

      if (target.classList.contains("__modal-detail-overlay")) {
        clearActive();
      }
    };

    document.addEventListener("click", handler);

    return () => document.removeEventListener("click", handler);
  }, [contentRef.current]);

  const activeItem = active > -1 ? portfolioBlocks[active] : null;

  return (
    <div className="Modal">
      <div
        className="__container __modal-overlay"
        onClick={(e) => {
          const target = e.target as HTMLElement;
          if (target.classList.contains("__modal-overlay")) {
            buttonHandler();
          }
        }}
      >
        <div className="__modal-box">
          <div className="__portfolio-blocks">
            <div className="__close-button" onClick={buttonHandler}>
              <CloseIcon width="45px" height="45px" />
            </div>
            {portfolioBlocks.map((item, idx) => (
              <ModalBlock
                idx={idx}
                item={item}
                setActive={setActive}
                setDetailVisibility={setDetailVisibility}
                key={item.id}
              />
            ))}
          </div>
          {detailVisibility && <div className="__modal-detail-overlay"></div>}
          <div
            ref={contentRef}
            className={`__modal-detail ${
              detailVisibility ? "_fade-in" : "_fade-out"
            }`}
          >
            <div
              className="__img"
              style={{ backgroundImage: `url(${activeItem?.img || ""})` }}
            ></div>
            <h4 className="__title">{activeItem?.title}</h4>
            <p className="__description">{activeItem?.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;

/* <Modal>
   <SomeView />
</Modal>

<Modal>
   <SomeContentForMobile />
</Modal> */
