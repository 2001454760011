import React, { FC, ComponentType } from "react";

interface IBlock {
  id?: number;
  Icon: ComponentType;
  title?: string;
  subtitle?: string;
  index: number;
  active: number;
}

const AboutBlock: FC<IBlock> = ({
  id,
  Icon,
  title,
  subtitle,
  index,
  active,
}) => (
  <div className={`__block ${active === index ? "__active" : ""}`}>
    <div className="__block-icon">
      <div className={id === 4 ? "__svg-hover-process" : "__svg-hover"}>
        <Icon />
      </div>

      <div className="__title">{title}</div>
    </div>
    <div>
      <div className="__subtitle">{subtitle}</div>
    </div>
  </div>
);

export default AboutBlock;
