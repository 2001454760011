export const portfolioBlocks = [
  {
    id: 1,
    img: "/portfolio/accounting.png",
    title: "Учет и автоматизация",
    subtitle: "Учёт и автоматизация вашего бизнеса.",
    description: `
    Учет и автоматизация - это два важных аспекта ведения бизнеса, которые позволяют эффективно управлять финансами, ресурсами и процессами.
    `,
  },
  {
    id: 2,
    img: "/portfolio/document.png",
    title: "Документооборот",
    subtitle: "Создание услуги по автоматизации документооборота.",
    description: `
    Автоматизация документооборота - это внедрение систем и технологий для автоматизации процессов создания, передачи, обработки и хранения документов в организации. 
    `,
  },
  {
    id: 3,
    img: "/portfolio/sms-service.png",
    title: "SMS сервис",
    subtitle:
      "Это услуга, в рамках которой на мобильный телефон направляются SMS-сообщение.",
    description: `
    Сервис SMS-оповещения представляет собой специализированную платформу или инструмент, который позволяет организациям отправлять автоматические SMS-сообщения для целей уведомления, оповещения или коммуникации с клиентами, партнерами, сотрудниками или другими заинтересованными сторонами. Этот сервис широко используется в различных областях, включая коммерческие, государственные, образовательные и некоммерческие секторы.
    `,
  },
  {
    id: 4,
    img: "/portfolio/document-exchange.png",
    title: "Портал для обмена документов",
    subtitle:
      "Портал для обмена отсканированного документа между организациями.",
    description: `
    Портал - для обмена в виде отсканированного документа для одной организации или же связывает между собой несколько организаций.
      `,
  },
  {
    id: 5,
    img: "/portfolio/keeper.png",
    title: "Кипер",
    subtitle: "Создание сервиса по автоматизация ресторанов.",
    description: `
    Кипер - это использование технологических решений для оптимизации и улучшения различных аспектов управления ресторанным бизнесом. Это включает в себя автоматизацию бизнес-процессов, управление заказами, службу доставки, учет, взаимодействие с клиентами и многое другое.
      `,
  },
  {
    id: 6,
    img: "/portfolio/market.png",
    title: "Интернет магазин",
    subtitle:
      "Широкий выбор готовых сайтов для интернет магазина в разных вариациях.",
    description: `
    Интернет-магазин - это виртуальная платформа, предоставляющая возможность покупателям приобретать товары и услуги через интернет. Он является электронной альтернативой традиционным физическим магазинам, позволяя покупателям делать заказы и оплачивать товары в онлайн-режиме. Интернет-магазины могут специализироваться на различных товарных категориях, например, одежде, электронике, косметике, продуктах питания и т.д.
      `,
  },
  {
    id: 7,
    img: "/portfolio/telegram-bot.png",
    title: "Телеграм бот",
    subtitle: "Создание телеграм бота под любой бизнес процесс.",
    description: `
    Телеграм-бот - это программа, созданная для работы в мессенджере Telegram, который обеспечивает автоматизированный обмен информацией и выполнение различных задач через чат-интерфейс. Телеграм-боты могут выполнять разнообразные функции, от предоставления информации и ответов на вопросы до выполнения более сложных задач, таких как оформление заказов или управление делами. 
      `,
  },
  {
    id: 8,
    img: "/portfolio/admin-panel.png",
    title: "Турникет",
    subtitle:
      "Проект, в котором отслеживается вход и выход сотрудников, учеников или студентов.",
    description: `
    Это проект, в котором отслеживается вход и выход в организации сотрудников и производится контроль, также подходит для учебных заведений, в которых также контролируется приход и уход сотрудников, учеников или студентов. 
      `,
  },
];

export const firstTwoPortfolio = portfolioBlocks.slice(0, 2);
