import React, { FC } from "react";

interface IBlock {
  id: number;
  img: string;
  title: string;
  subtitle: string;
}

interface IPortfolioBlock {
  block: IBlock;
  mobileModalHandler: (id: number) => void;
  index: number;
  active: number;
}

const PortfolioBlock: FC<IPortfolioBlock> = ({
  block,
  mobileModalHandler,
  index,
  active,
}) => (
  <div
    className={`__block ${index === active ? "_active" : ""}`}
    onClick={() => mobileModalHandler(block.id)}
  >
    <div
      className="__images"
      style={{ backgroundImage: `url(${block.img})` }}
    ></div>
    <div className="__block-text">
      <div className="__title">{block.title}</div>
      <div className="__subtitle">{block.subtitle}</div>
    </div>
  </div>
);

export default PortfolioBlock;
