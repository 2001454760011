import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { MainLayout } from "../../layouts";
import "./index.scss";
import { LayoutContent } from "../../components";
import { Routes } from "../../shared/utils/routes";

const Home: FC = () => {
  const navigate = useNavigate();
  const handleNextPage = () => {
    navigate(Routes.about);
  };

  const handleNextPageScroll = (next?: boolean) => {
    if (next) {
      navigate(Routes.techStack);
    } else {
      navigate(Routes.about);
    }
  };

  return (
    <MainLayout
      image="/images/photo1.png"
      onNextPageScroll={handleNextPageScroll}
    >
      <LayoutContent image="/images/photo1.png" onNextPage={handleNextPage}>
        <div className="Home">
          <div className="__container">
            <div className="__title">
              <span className="__colored-title">RowTech </span>| Разработка
              корпоративного программного обеспечения, дизайн и консалтинг.
            </div>
            <div className="__text">
              Мы предлагаем широкий спектр услуг по разработке, тестированию и
              поддержке программного обеспечения. Наши специалисты используют
              современные технологии и инструменты, чтобы создавать качественные
              продукты. Мы работаем с клиентами из разных стран и готовы
              предложить индивидуальный подход к каждому проекту. Если вы ищете
              надежного партнера в мире IT, то мы будем рады сотрудничеству с
              вами.
            </div>
          </div>
        </div>
      </LayoutContent>
    </MainLayout>
  );
};

export default Home;
