import { FC, useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { MainLayout } from "../../layouts";
import LayoutContent from "../../components/LayoutContent";
import { Routes } from "../../shared/utils/routes";
import "./index.scss";
import { blocks } from "./constants";
import AboutBlock from "./AboutBlocks";

const About: FC = () => {
  const navigate = useNavigate();

  const handleNextPage = () => {
    navigate(Routes.portfolio);
  };
  const handleNextPageScroll = (next?: boolean) => {
    if (next) {
      navigate(Routes.home);
    } else {
      navigate(Routes.portfolio);
    }
  };

  const [left, setLeft] = useState(0);
  const scrollRef = useRef<HTMLDivElement>(null);

  const widthInner = window.innerWidth;

  let activePlus: number;
  let activeDivide: number;

  let leftFirst: number;
  let leftSecond: number;

  if (widthInner <= 575 && widthInner > 360) {
    activePlus = 150;
    activeDivide = 300;

    leftFirst = 240;
    leftSecond = 80;
  } else if (widthInner <= 360) {
    activePlus = 140;
    activeDivide = 280;

    leftFirst = 220;
    leftSecond = 60;
  }
  const getActive = (value: number) =>
    Math.ceil((value + activePlus) / activeDivide) - 1;

  const getCalcLeft = (n: number) => n * leftFirst + n * leftSecond;

  const debounceScroll = useDebouncedCallback((value: number) => {
    const n = getActive(value);

    const leftInner = getCalcLeft(n);

    setLeft(leftInner);

    if (scrollRef.current) {
      scrollRef.current.scrollLeft = leftInner;
    }
  }, 300);

  useEffect(() => {
    const scrollHandler = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const { scrollLeft = 0 } = target;

      setLeft(scrollLeft);
      debounceScroll(scrollLeft);
    };

    scrollRef.current?.addEventListener("scroll", scrollHandler);
    return () =>
      scrollRef.current?.removeEventListener("scroll", scrollHandler);
  }, []);

  const active = getActive(left);

  return (
    <MainLayout
      image="./images/photo2.png"
      onNextPageScroll={handleNextPageScroll}
    >
      <LayoutContent
        image="./images/photo2.png"
        onNextPage={handleNextPage}
        title="Чем мы занимаемся?"
      >
        <div className="About">
          <div>
            <div className="__blocks" ref={scrollRef}>
              {blocks.map(({ id, Icon, title, subtitle }, index) => (
                <AboutBlock
                  key={id}
                  id={id}
                  Icon={Icon}
                  title={title}
                  subtitle={subtitle}
                  active={active}
                  index={index}
                />
              ))}
            </div>
          </div>
        </div>
      </LayoutContent>
    </MainLayout>
  );
};

export default About;
