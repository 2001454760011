export const team = [
  {
    id: 1,
    image: "/team/user1.png",
    name: "Andree Wijaya",
    job: "CEO/CTO",
    status: false,
  },
  {
    id: 2,
    image: "/team/user2.png",
    name: "Elisabeth Be",
    job: "HR",
    status: false,
  },
  {
    id: 3,
    image: "/team/user1.png",
    name: "Mark",
    job: "Front-end",
    status: false,
  },
  {
    id: 4,
    image: "/team/user3.png",
    name: "Jack Nicolson",
    job: "CFO",
    status: false,
  },
];

export const buttons = [
  { id: 1, status: true },
  { id: 2, status: false },
  { id: 3, status: false },
  { id: 4, status: false },
];
