export const langOptions = [
  {
    label: "Ru",
    value: 1,
  },
  {
    label: "En",
    value: 2,
  },
  {
    label: "Tj",
    value: 3,
  },
];
