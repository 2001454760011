import React, { useMemo } from "react";
import "./App.scss";
import { Routes, Route } from "react-router-dom";
import { routesList } from "./shared/utils/routes";

function App() {
  const visibleRoutes = useMemo(
    () => routesList.filter((item) => item.visibility !== false),
    []
  );

  return (
    <div className="App">
      <Routes>
        {visibleRoutes.map((item) => (
          <Route key={item.path} path={item.path} element={item.element} />
        ))}
        <Route path="*" element={<h1>Page not found!</h1>} />
      </Routes>
    </div>
  );
}

export default App;
