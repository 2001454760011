import { FC, useState } from "react";
import { ReactComponent as MenuBarIcon } from "../../shared/assets/icons/menu-bar.svg";
import "./index.scss";

interface IMenuButton {
  toggleMenu?: () => void;
}

const MenuButton: FC<IMenuButton> = ({ toggleMenu }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={`__icon-block ${isHovered ? "_active" : ""}`}
      onClick={toggleMenu}
      onTouchStart={() => setIsHovered(true)}
      onTouchEnd={() => setIsHovered(false)}
    >
      <MenuBarIcon />
    </div>
  );
};
export default MenuButton;
