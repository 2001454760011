import React, { FC, useState } from "react";
import SelectedVacancyModal from "../../components/SelectedVacancyModal";

interface IVacancies {
  item: { id: number; title: string; subtitle: string };
  chooseVacancy: (id: number) => void;
}
const VacanciesBlocks: FC<IVacancies> = ({ item, chooseVacancy }) => {
  const { title, subtitle, id } = item;

  return (
    <div className="__block" onClick={() => chooseVacancy(id)}>
      <div className="__title">{title}</div>
      <div className="__subtitle">{subtitle}</div>
    </div>
  );
};

export default VacanciesBlocks;
