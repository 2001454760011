import { useState, FC, useEffect } from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { Form } from "../../components";
import Menu from "../../components/Menu";
import MenuButton from "../../components/MenuButton";
import LangSelect from "../../components/Select/variant/_languages";
import { aboutUs } from "./constants";
import OrderModal from "../../components/OrderModal";
import { services } from "../../components/OrderModal/constant";
import { IService } from "../../components/Form";
import ContactsBlock from "./ContactsBlock";
import { ReactComponent as AngleLeft } from "../../shared/assets/icons/angle-left.svg";

const Contacts: FC = () => {
  const [expanded, setExpanded] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedService, setSelectedService] = useState<
    IService | undefined | null
  >();

  const [pushSelectedService, setPushSelectedService] = useState<
    IService | undefined | null
  >();

  const [toggleStatus, setToggleStatus] = useState<IService[]>(services);

  const toggleMenu = () => setExpanded(!expanded);

  const pushSelectedServiceHandler = () => {
    // setPushSelectedService({
    //   ...selectedService,
    //   status: true,
    // });

    setPushSelectedService(selectedService);
  };

  const toggleModal = () => {
    setSelectedService(null);
    setToggleStatus(
      toggleStatus.map((item) => {
        if (pushSelectedService) return item;
        return {
          ...item,
          status: false,
        };
      })
    );
    setModal(!modal);
  };

  const toggleModalSelect = () => {
    setSelectedService(null);
    setModal(!modal);
  };

  const chooseService = (id: number) => {
    setSelectedService(services.find((item) => item.id === id));
    setToggleStatus(
      toggleStatus.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            status: true,
          };
        }
        return {
          ...item,
          status: false,
        };
      })
    );
  };

  const history = useNavigate();
  const handleGoBack = () => {
    history(-1);
  };

  return (
    <div className="Contacts">
      <div className="__menu-icon">
        <MenuButton toggleMenu={toggleMenu} />
      </div>
      <div className="__return-button-block" onClick={handleGoBack}>
        <AngleLeft className="__angle-left" />
      </div>

      <div className="__main-block">
        <div className="__blocks">
          <div className="__block">
            <div className="__title">Наши контакты</div>
            {aboutUs.map(({ id, Icon, href, text }) => (
              <ContactsBlock
                id={id}
                Icon={Icon}
                href={href}
                text={text}
                key={id}
              />
            ))}
          </div>
          <Form
            modal={modal}
            toggleModal={toggleModal}
            pushSelectedService={pushSelectedService}
          />
        </div>
      </div>
      <Menu active={expanded} toggleMenu={toggleMenu} />
      <div className="__langSelect">
        <LangSelect />
      </div>
      {modal && (
        <OrderModal
          toggleModal={toggleModal}
          chooseService={chooseService}
          selectedService={selectedService}
          pushSelectedServiceHandler={pushSelectedServiceHandler}
          toggleStatus={toggleStatus}
          toggleModalSelect={toggleModalSelect}
          pushSelectedService={pushSelectedService}
        />
      )}
    </div>
  );
};

export default Contacts;
