import { FC, ComponentType } from "react";

interface IBlock {
  id: number;
  Icon: ComponentType;
  href?: string;
  text: string;
}

const ContactsBlock: FC<IBlock> = ({ id, Icon, href, text }) => {
  const windowWidth = window.innerWidth;
  const phone =
    windowWidth < 575 ? (
      <a className="__phone" href={`tel:${text}`}>
        {text}
      </a>
    ) : (
      <div>{text}</div>
    );
  return (
    <div className="__about-us" key={id}>
      <div className="__icons">
        <Icon />
      </div>

      {
        // eslint-disable-next-line no-nested-ternary
        href === "mailto:Info@row.tj" ? (
          <a className="__link" href={href}>
            {text}
          </a>
        ) : text === "+992550650550" ? (
          phone
        ) : (
          <div className="__text">{text}</div>
        )
      }
    </div>
  );
};

export default ContactsBlock;
