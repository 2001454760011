import { FC, useRef, useState } from "react";
import "./index.scss";

export interface ISelectedPortfolio {
  id: number;
  img: string;
  title: string;
  subtitle: string;
  description: string;
}

interface IMobileModal {
  mobileModalHandler: (id?: number) => void;
  selectedPortfolio: ISelectedPortfolio | undefined;
  mobileModal: boolean;
}

const MobileModal: FC<IMobileModal> = ({
  mobileModalHandler,
  selectedPortfolio,
  mobileModal,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const trancateText = (description: string | undefined, maxWords: number) => {
    if (description) {
      if (description?.length < maxWords) return description;
      const cutOutDescription = description.substr(0, maxWords);
      return `${cutOutDescription}...`;
    }
    return "";
  };

  const maxWords = 150;
  const сutOutDescription = trancateText(
    selectedPortfolio?.description,
    maxWords
  );

  if (mobileModal) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }

  return (
    <div
      className="Mobile-modal"
      onClick={(e) => {
        const target = e.target as HTMLElement;
        if (target.classList.contains("Mobile-modal")) {
          mobileModalHandler();
        }
      }}
    >
      <div className="__mobile-modal">
        <div
          className="__mobile-image"
          style={{ backgroundImage: `url(${selectedPortfolio?.img})` }}
        ></div>
        {/* <div className="__close-icon" onClick={() => mobileModalHandler()}>
          <CloseIcon width="45px" height="45px" />
        </div> */}
        <div className="__content">
          <div className="__mobile-title">{selectedPortfolio?.title}</div>
          <div className="__mobile-description">{сutOutDescription}</div>
          <div className="__block-center">
            <div
              className={`__button-block ${isHovered ? "_active" : ""}`}
              onClick={() => mobileModalHandler()}
              onTouchStart={() => setIsHovered(true)}
              onTouchEnd={() => setIsHovered(false)}
            >
              <div className="__button">Закрыть</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileModal;
