import React, { FC } from "react";

interface INavbarBlock {
  title: string;
  href: string;
}
const NavbarBlock: FC<INavbarBlock> = ({ href, title }) => (
  <div className={`__block-links _${title}`}>
    <a className="__links" href={title === "email" ? `mailto:${href}` : href}>
      {title}
    </a>
  </div>
);
export default NavbarBlock;
