import React, { ComponentType, FC } from "react";

interface IMobileFooterBlock {
  id: number;
  text: string;
  Icon: ComponentType;
  href?: string;
}

const MobileFooterBlock: FC<IMobileFooterBlock> = ({
  id,
  text,
  Icon,
  href,
}) => (
  <div className="__about-us" key={id}>
    <div className="__icons">
      <div className="__icons">
        <Icon />
      </div>
    </div>

    {
      // eslint-disable-next-line no-nested-ternary
      href === "mailto:Info@row.tj" ? (
        <a className="__link" href={href}>
          {text}
        </a>
      ) : text === "+992550650550" ? (
        <a className="__tel" href="tel:+992 550650550">
          <div className="__text">{text}</div>
        </a>
      ) : (
        <div className="__info">{text}</div>
      )
    }
  </div>
);

export default MobileFooterBlock;
