import React, { FC } from "react";

interface IItem {
  id: number;
  status: boolean;
  classBackground: string;
  img: string;
  classText: string;
  title: string;
}

interface IOrderModal {
  item: IItem;
  chooseService: (id: number) => void;
}

const OrderModalBlock: FC<IOrderModal> = ({ item, chooseService }) => (
  <div
    className="__services-blocks"
    key={item.id}
    onClick={() => chooseService(item.id)}
  >
    <div
      className={`__services-round ${item.classBackground} ${
        item.status ? "_active" : ""
      }`}
    >
      <img className="__services-image" src={item.img} alt="" />
    </div>
    <div className={`__services-title ${item.classText}`}>{item.title}</div>
  </div>
);

export default OrderModalBlock;
