import React, { FC } from "react";
import "./index.scss";
import { inputs } from "./constants";
import { ReactComponent as CloseIcon } from "../../shared/assets/icons/close.svg";

interface ISelectedVacancyModal {
  selectedVacancy: null | {
    id: number;
    title: string;
    subtitle: string;
    requirements: { title: string; items: string[] };
    responsibilities: { title: string; items: string[] };
    primaryRequirements: { title: string; items: string[] };
    offer: { title: string; items: string[] };
    sendCv: { title: string; bold: string; items: string[] };
  };
  isSelectedVacancy: boolean;
  chooseVacancy: (id?: number) => void;
}

const SelectedVacancyModal: FC<ISelectedVacancyModal> = ({
  selectedVacancy,
  isSelectedVacancy,
  chooseVacancy,
}) => (
  <div
    onClick={(e) => {
      const target = e.target as HTMLElement;
      if (target.classList.contains("SelectedVacancyModal")) {
        chooseVacancy();
      }
    }}
    className={`SelectedVacancyModal ${isSelectedVacancy ? "_active" : ""}`}
  >
    <div className={`__icon ${isSelectedVacancy ? "_visible" : ""}`}>
      <CloseIcon onClick={() => chooseVacancy()} width={45} height={45} />
    </div>
    <div className="__container">
      <div className={`__modal ${isSelectedVacancy ? "_active" : ""}`}>
        <div className="__block">
          <div>
            <div className="__mobile-block">
              <div className="__main-title">{selectedVacancy?.title}</div>
              <div className="__mobile-close-icon">
                <CloseIcon
                  onClick={() => chooseVacancy()}
                  width={45}
                  height={45}
                />
              </div>
            </div>
            <div className="__first-block">
              <div className="__requirements">
                <div className="__title">
                  {selectedVacancy?.requirements.title}
                </div>
                <div>
                  {selectedVacancy?.requirements.items.map((item) => (
                    <div className="__subtitle" key={item}>
                      {item}
                    </div>
                  ))}
                </div>
              </div>
              <div className="__responsibilities">
                <div className="__title">
                  {selectedVacancy?.responsibilities.title}
                </div>
                <div>
                  {selectedVacancy?.responsibilities.items.map((item) => (
                    <div className="__subtitle" key={item}>
                      {item}
                    </div>
                  ))}
                </div>
              </div>
              <div className="__primary-requirements">
                <div className="__title">
                  {selectedVacancy?.primaryRequirements.title}
                </div>
                <div>
                  {selectedVacancy?.primaryRequirements.items.map((item) => (
                    <div className="__subtitle" key={item}>
                      {item}
                    </div>
                  ))}
                </div>
              </div>
              <div className="__offer">
                <div className="__title">{selectedVacancy?.offer.title}</div>
                <div>
                  {selectedVacancy?.offer.items.map((item) => (
                    <div className="__subtitle" key={item}>
                      {item}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="__second-block">
            <div className="__cv-title">{selectedVacancy?.sendCv.title}</div>
            <div>
              {selectedVacancy?.sendCv.items.map((item, index) => (
                <div className="__cv-subtitle" key={item}>
                  {item}
                  <a
                    className="__email"
                    href={`mailto:${selectedVacancy.sendCv.bold}`}
                  >
                    {index === 1 ? selectedVacancy.sendCv.bold : null}
                  </a>
                </div>
              ))}
            </div>
            <div className="__inputs-block">
              {inputs.map((item) => (
                <input className="__inputs" key={item} placeholder={item} />
              ))}
              <textarea placeholder="Расскажите о себе" />
            </div>
            <div className="__file-block">
              <label htmlFor="#" className="__file-input">
                <div className="__file-input-block">
                  <img src="./images/file.png" alt="" /> <br />
                  <span className="__file-text">Прикрепить файл</span>
                  <input type="file" />
                </div>
              </label>

              <div className="__button-block">
                <div className="__button">Отправить</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default SelectedVacancyModal;
