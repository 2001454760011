import { ReactComponent as Twitter } from "../../shared/assets/icons/twitter.svg";
import { ReactComponent as Facebook } from "../../shared/assets/icons/facebook.svg";
import { ReactComponent as Instagram } from "../../shared/assets/icons/instagram.svg";

export const socialMediasIcon = [
  {
    id: 1,
    Img: Twitter,
    href: "Info@row.tj",
  },
  {
    id: 2,
    Img: Facebook,
    href: "https://www.facebook.com/profile.php?id=100088263955875",
  },
  {
    id: 3,
    Img: Instagram,
    href: "https://instagram.com/rowtech___?igshid=OGQ5ZDc2ODk2ZA==",
  },
];
