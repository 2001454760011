import React, { FC } from "react";
import { ReactComponent as EyeIcon } from "../../../shared/assets/icons/eye.svg";

interface IItem {
  id: number;
  img: string;
  title: string;
  subtitle: string;
}

interface IModalBlock {
  idx: number;
  item: IItem;
  setActive: (idx: number) => void;
  setDetailVisibility: (isVisible: boolean) => void;
}

const ModalBlock: FC<IModalBlock> = ({
  idx,
  item,
  setActive,
  setDetailVisibility,
}) => (
  <div className="__portfolio-block" key={item.id}>
    <div className="__img" style={{ backgroundImage: `url(${item.img})` }}>
      <span
        onClick={() => {
          setActive(idx);
          setDetailVisibility(true);
        }}
      >
        <EyeIcon width="45px" height="45px" />
      </span>
    </div>
    <div className="__title">{item.title}</div>
    <div className="__subtitle">{item.subtitle}</div>
  </div>
);
export default ModalBlock;
