export const services = [
  {
    id: 1,
    title: "CRM система",
    img: "/contacts/crm.png",
    classText: "_crm-text",
    classBackground: "_crm-background",
    status: false,
  },
  {
    id: 2,
    title: "UX/UI дизайн",
    img: "/contacts/ui.png",
    classText: "_ui-text",
    classBackground: "_ui-background",
    status: false,
  },
  {
    id: 3,
    title: "Веб сайт",
    img: "/contacts/web.png",
    classText: "_web-text",
    classBackground: "_web-background",
    status: false,
  },
  {
    id: 4,
    title: "Разработка приложения",
    img: "/contacts/app.png",
    classText: "_app-text",
    classBackground: "_app-background",
    status: false,
  },
  {
    id: 5,
    title: "Другое",
    img: "/contacts/double-arrow.png",
    classText: "_other-text",
    classBackground: "_other-background",
    status: false,
  },
];
