import { FC } from "react";
import "./index.scss";
import { ReactComponent as ToggleButton } from "../../shared/assets/icons/toggle-button.svg";
import { IService } from "../Form";
import OrderModalBlock from "./OrderModalBlocks";

interface IServiceOrder {
  id: number;
  title: string;
  img: string;
  classText: string;
  classBackground: string;
  status: boolean;
}

interface IModal {
  toggleModal: () => void;
  chooseService: (id: number) => void;
  selectedService: IService | undefined | null;
  pushSelectedServiceHandler: () => void;
  toggleStatus: IServiceOrder[];
  toggleModalSelect: () => void;
  pushSelectedService: IService | undefined | null;
}

const OrderModal: FC<IModal> = ({
  toggleModal,
  chooseService,
  selectedService,
  pushSelectedServiceHandler,
  toggleStatus,
  toggleModalSelect,
  pushSelectedService,
}) => {
  const modalToggle = () => {
    pushSelectedServiceHandler();
    toggleModalSelect();
  };
  return (
    <div
      className="OrderModal"
      onClick={(e) => {
        const target = e.target as HTMLElement;
        if (target.classList.contains("OrderModal")) {
          toggleModal();
        }
      }}
    >
      <div className="__modal-box">
        <div className="__modal-content">
          <div className="__toggle-button" onClick={toggleModal}>
            <ToggleButton />
          </div>
          <div className="__modal-title">Выберите необходимую услугу</div>
          <div className="__modal-subtitle">
            После выбора наших услуг, мы рассмотрим ваш запрос и свяжемся с вами
            для дальнейшего обсуждения.
          </div>
          <div className="__services">
            <div className="__services-block">
              {toggleStatus.map((item) => (
                <OrderModalBlock
                  item={item}
                  chooseService={chooseService}
                  key={item.id}
                />
              ))}
            </div>
          </div>
          {selectedService || pushSelectedService ? (
            <div className="__button-block" onClick={modalToggle}>
              <div>Выбрать</div>
            </div>
          ) : (
            <div
              className="__button-block-disabled"
              style={
                selectedService || pushSelectedService
                  ? { opacity: 0, transition: "all 0.3s ease" }
                  : { opacity: 1 }
              }
            >
              <button className="__button-disabled" type="submit">
                Выбрать
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderModal;
