import React, { FC } from "react";
import { Link } from "react-router-dom";
import "./index.scss";

const Footer: FC = () => (
  <footer className="Footer">
    <div className="__contacts">
      <Link className="__link" to="/contacts">
        Оформление заказа
      </Link>
    </div>
  </footer>
);

export default Footer;
