import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { MainLayout } from "../../layouts";
import { LayoutContent } from "../../components";
import { Routes } from "../../shared/utils/routes";
import "./index.scss";
import { technologies } from "./constants";

const OurStack: FC = () => {
  const navigate = useNavigate();
  const handleNextPage = () => {
    navigate(Routes.vacancies);
  };

  const handleNextPageScroll = (next?: boolean) => {
    if (next) {
      navigate(Routes.techStack);
    } else {
      navigate(Routes.vacancies);
    }
  };

  return (
    <MainLayout
      onNextPageScroll={handleNextPageScroll}
      image="./images/photo4.png "
    >
      <LayoutContent
        image="./images/photo4.png "
        onNextPage={handleNextPage}
        title="Стэк технологий"
      >
        <div className="Stack">
          {/* <div className="__blocks">
            {technologies.map((item) => (
              <div className="__block" key={item.id}>
                <div className="__title" style={{ marginRight: "27px" }}>
                  {item.title}
                </div>
                {item.images.map((img) => (
                  <img
                    style={{ marginRight: "27px" }}
                    src={img}
                    alt=""
                    key={img}
                  />
                ))}
              </div>
            ))}
          </div> */}
          <div className="__block">
            {technologies.map((item) => (
              <img className="__img-block" src={item} alt="" key={item} />
            ))}
          </div>
        </div>
      </LayoutContent>
    </MainLayout>
  );
};

export default OurStack;
