import React, { FC } from "react";

interface IItem {
  image: string;
  name: string;
  job: string;
}

interface ISliderBlocks {
  idx: number;
  item: IItem;
  active: number;
}

const SliderBlocks: FC<ISliderBlocks> = ({ idx, item, active }) => (
  <div
    className={`__item ${
      // eslint-disable-next-line no-nested-ternary
      active === idx ? "active" : active > idx ? "inactive" : ""
    }`}
  >
    <img className="__image" src={item.image} alt="" />
    <div className="__name">{item.name}</div>
    <div className="__job">{item.job}</div>
  </div>
);

export default SliderBlocks;
